const adUnitConfig: { [key: string]: string } = {
  homepage_clubic: 'homepage-rjs',
  '/telecharger/': 'telechargement-homepage-rjs',
  download: 'telechargement-fiche-rjs',
  news: 'contenu-actu-rjs',
  review: 'contenu-actu-rjs',
  legacy: 'contenu-actu-rjs',
  'long-read': 'contenu-actu-rjs',
  deals: 'contenu-actu-rjs',
  'best-pick': 'contenu-comparatif-rjs',
  guide: 'contenu-actu-rjs',
  tutorial: 'contenu-actu-rjs',
  download_listing: 'telechargement-listing-rjs',
  item: 'contenu-actu-rjs',
  section: 'section-rjs',
  product: 'contenu-produit-rjs',
};

export default adUnitConfig;
