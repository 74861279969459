import styled, { css } from 'styled-components';
import React, { useCallback, useState, useRef, useContext } from 'react';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import dynamic from 'next/dynamic';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import Link from 'next/link';
import { NavLink } from '../../atoms/nav-link';
import { formatNavLinksFromComponent } from '../../utils/headerFormatter';
import { PrimaryNavItems } from './primary-nav-items';
import { useSdkConfig } from '../../utils/config';
import { PrimarySubNav } from './primary-sub-nav';
import { UserProfile } from './user-profile';
import { AuthContext } from '../../utils/AuthContext';

const BurgerMenu = dynamic(() => import('@marty-js/design/src/icons/burgerMenu'));
const Clubic = dynamic(() => import('@marty-js/design/src/icons/clubic'));
const Close = dynamic(() => import('@marty-js/design/src/icons/close'));
const Moon = dynamic(() => import('@marty-js/design/src/icons/moon'));
const Search = dynamic(() => import('@marty-js/design/src/icons/search'));

const Container = styled.header<{ isMobileMenuDisplayed: boolean }>`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  background-color: var(--background-color);
  transition: background-color 1s;
  z-index: 1000002;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      position: initial;
      justify-content: center;
    `,
  )}
`;

const NavBar = styled.div`
  border-bottom: 1px solid #edf0f4;
  color: var(--title-color);
  padding: 0 15px;
  width: 100%;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-high {
        padding-bottom: 40px;
      }
    `,
  )}
`;

const TopNav = styled.div`
  display: flex;
  align-items: center;
  margin: 8px auto;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: auto;
      max-width: 1250px;
      padding: 15px 0;
      height: 71px;
    `,
  )}
`;

const SubNav = styled.ul`
  background-color: var(--background-color-sub);
  color: var(--title-color);
  align-items: center;
  flex-basis: 100%;
  justify-content: center;
  left: 0;
  line-height: 1;
  padding: 0;
  width: 100%;

  > ul {
    margin: auto;
    max-width: 1200px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      height: 40px;
      position: absolute;
      text-align: center;
      top: 72px;
    `,
  )}
`;

const LeftNav = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 calc(50% - (158px / 2));

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}

  > * {
    display: flex;
    align-items: center;
  }
`;

const RightNav = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 1 1 calc(50% - (158px / 2));
  gap: 8px;
  margin-bottom: 4px;
  right: 0;

  > * {
    display: flex;
    align-items: center;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex: initial;
      svg {
        box-sizing: content-box;
        padding: 9px;
        width: 24px;
        height: 24px;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.X_LARGE,
    css`
      right: 50px;
    `,
  )}
`;

const NavLinks = styled.nav`
  background-color: var(--background-color);
  bottom: 0;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column-reverse;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 55px;
  right: 0;
  transition: all 1s;
  transform: translateX(0);
  z-index: 1;

  &.mod-hide-mobile {
    transform: translateX(100%);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: row;
      position: static;
      transform: initial;
      z-index: 10;

      &.mod-hide-mobile {
        display: flex;
        transform: initial;
      }
    `,
  )}
`;

const DarkModeButton = styled.button`
  display: flex;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const MobileDarkModeButton = styled.button`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
    `,
  )}
`;

const MobileClose = styled.button`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const MobileBurger = styled.button`
  margin-left: 8px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const RootLogoLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  position: relative;

  svg {
    background-color: ${(props) => props.theme.palette.red};
    border-radius: 6px;
    width: 71px;
    height: 38px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin-bottom: 4px;

      svg {
        width: 82px;
        height: 44px;
      }
    `,
  )}
`;

const UserIcon = styled.div`
  display: flex;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const MobileUserIcon = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
    `,
  )}
`;

interface Props {
  underHeaderComponent: any;
  headerComponent: any;
}

export const Header: React.FC<Props> = ({ headerComponent, underHeaderComponent }) => {
  const t = useTranslation();
  const { avatar } = useContext(AuthContext);

  const sdkConfig = useSdkConfig();

  const navLinks = formatNavLinksFromComponent(headerComponent) ?? [];
  const subNavLinks = formatNavLinksFromComponent(underHeaderComponent) ?? [];

  const [isMobileMenuDisplayed, setIsMobileMenuDisplayed] = useState<boolean>(false);
  const navRef = useRef(null);

  const displayMobileMenu = useCallback(() => {
    setIsMobileMenuDisplayed(true);
    navRef.current.scroll({ top: -navRef.current.scrollHeight });
  }, []);

  const hideMobileMenu = useCallback(() => {
    setIsMobileMenuDisplayed(false);
  }, []);

  const { switchTheme, isDarkTheme } = useThemeSwitcher();

  return (
    <Container isMobileMenuDisplayed={isMobileMenuDisplayed}>
      <NavBar className={subNavLinks.length ? 'mod-high' : ''} data-testid="primary-navbar">
        <TopNav>
          <LeftNav>
            <DarkModeButton
              onClick={() => switchTheme()}
              type="button"
              role="switch"
              aria-checked={isDarkTheme}
              aria-label={t('header.darkModeLabel')}
            >
              <Moon width={24} height={24} color={isDarkTheme ? 'white' : 'black'} />
            </DarkModeButton>
            {sdkConfig.sitename === 'site-clubic' && (
              <UserIcon>
                <UserProfile icon={avatar} />
              </UserIcon>
            )}
          </LeftNav>
          <RootLogoLink href="/" title={t('header.altLogo')}>
            <Clubic width={82} height={44} />
          </RootLogoLink>
          <NavLinks className={isMobileMenuDisplayed ? '' : 'mod-hide-mobile'} ref={navRef}>
            <PrimaryNavItems navLinks={navLinks} />
            {subNavLinks.length ? (
              <SubNav>
                <PrimarySubNav navLinks={subNavLinks} />
              </SubNav>
            ) : null}
          </NavLinks>
          <RightNav>
            <MobileDarkModeButton
              onClick={() => switchTheme()}
              type="button"
              role="switch"
              aria-checked={isDarkTheme}
              aria-label={t('header.darkModeLabel')}
            >
              <Moon width={24} height={24} color={isDarkTheme ? 'white' : 'black'} />
            </MobileDarkModeButton>
            {sdkConfig.sitename === 'site-clubic' && (
              <MobileUserIcon>
                <UserProfile icon={avatar} />
              </MobileUserIcon>
            )}
            <Link href="/search" title={t('header.search')}>
              <Search width={24} height={24} color={isDarkTheme ? 'white' : 'black'} />
            </Link>
            {isMobileMenuDisplayed ? (
              <MobileClose onClick={hideMobileMenu} type="button" aria-label={t('header.hideMenuLabel')}>
                <Close width={32} height={32} color={isDarkTheme ? 'white' : 'black'} />
              </MobileClose>
            ) : (
              <MobileBurger onClick={displayMobileMenu} type="button" aria-label={t('header.displayMenuLabel')}>
                <BurgerMenu width={24} height={24} color={isDarkTheme ? 'white' : 'black'} />
              </MobileBurger>
            )}
          </RightNav>
        </TopNav>
      </NavBar>
    </Container>
  );
};
